
import {defineComponent, onMounted, ref, onUnmounted} from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import HiddenFileInput from '@/components/HiddenFileInput.vue';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import UploadIcon from '@/components/icons/UploadIcon.vue';
import UndoIcon from '@/components/icons/UndoIcon.vue';
import FileImage from '@/components/icons/FileImage.vue';
import MailIcon from '@/views/layouts/AppLayout/icons/MailIcon.vue';
import uploadImageFiles from '@/utils/uploadSignupImageFiles';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import bankCode from '@/constants/bankCode';
import Select from '@/components/Select.vue';
import PictureIcon from '@/views/layouts/AppLayout/icons/PictureIcon.vue';

export default defineComponent({
  name: 'MemberEdit',
  components: {
    Select,
    Container,
    IconBase,
    CheckCircleIcon,
    FileImage,
    UndoIcon,
    MailIcon,
    ArrowLeftIcon,
    HiddenFileInput,
    UploadIcon,
    PictureIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const memberDetail = ref<any>(null);
    const memberIdParam = ref<number>();
    const bankOptions = ref(bankCode);
    const imgFiles = ref({
      bankbookCopy: '',
      businessLicense: '',
    });
    const onlyNumberAccount = (input) => {
      memberDetail.value.partner.bankAccount = input.replace(/[^0-9]/g, '');
    };

    const changeAddress = () => {
      memberDetail.value.member.defaultShippingAddress.requireUpdate = true;
    };
    const openMobileAuth = async () => {
      const {data} = await partnerAPI.memberAuth.identificationInfoUsingGET();
      const encodeData = (data as any).data;

      const form = document.createElement('form');
      form.setAttribute('name', 'formChk');
      form.setAttribute(
        'action',
        'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
      );
      form.setAttribute('target', 'popupChk');

      const field = document.createElement('input');
      field.setAttribute('name', 'm');
      field.setAttribute('value', 'checkplusService');
      form.appendChild(field);

      const field2 = document.createElement('input');
      field2.setAttribute('name', 'EncodeData');
      field2.setAttribute('value', encodeData);
      form.appendChild(field2);

      const htmlForm = document.getElementById('form');
      const chkForm = document.getElementById('formChk');
      if (chkForm) {
        if (chkForm.parentNode != null) {
          chkForm.parentNode.removeChild(chkForm);
        }
      }
      if (htmlForm != null) {
        htmlForm.append(form);
      }

      window.open(
        '',
        'popupChk',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );
      form.submit();
    };

    const handleChangeFile = (fieldName: string) => async (e) => {
      const [url] = await uploadImageFiles({
        files: e.target.files as FileList,
      });
      imgFiles.value[fieldName] = e.target.files && e.target.files[0].name;
      console.log('url', url, e.target, fieldName);
      if (url) {
        memberDetail.value.partner[fieldName] = url;
      } else {
        alert('이미지 업로드에 실패했습니다.');
      }
    };

    const gotoDetail = () => {
      router.go(-1);
    };

    const handleOpenSearchAddress = () => {
      new (window.daum as any).Postcode({
        oncomplete: function (data) {
          if (memberDetail.value.memberType === 'MEMBER') {
            memberDetail.value.member.defaultShippingAddress.postcode = data.zonecode;
            memberDetail.value.member.defaultShippingAddress.address1 = data.address;
            memberDetail.value.member.defaultShippingAddress.requireUpdate = true;
          } else {
            memberDetail.value.partner.postNo = data.zonecode;
            memberDetail.value.partner.address1 = data.address;
          }


          // console.group('postcode');
          // console.log('address ', data.address);
          // console.log('zonecode ', data.zonecode);
          // console.groupEnd();
        },
      }).open();
    };

    const resetPassword = async (memberId) => {
      try {
        const {data} = await partnerAPI.partnerMember.partnerMemberResetPassword({
          memberId,
        });
        alert(data.message);
      } catch (error) {
        console.error(error);
        alert(error);
      }
    };

    const mobileAuthCallbackHandler = ({data, origin}) => {
      if (origin.includes('api.blacklot.com')) {
        console.log('휴대퐁 인증 콜백 들어옴');
        console.log(data);
        const {name, birth, dupInfo, gender, mobile} = JSON.parse(data);
        if (memberDetail.value.memberType === 'MEMBER') {
          if (dupInfo === memberDetail.value.member.dupInfo) {
            memberDetail.value.member.mobile = mobile;
          } else {
            alert('본인 휴대폰이 아닙니다.');
          }
        } else {
          memberDetail.value.partner.ceoMobile = mobile;
        }
      }
    };

    const handleSubmit = async (memberId) => {
      const body = memberDetail.value;
      console.log('body', body)
      try {
        const {data} = await partnerAPI.partnerMember.partnerMemberUpdate({
          body
        });
        alert(data.message);
        router.go(-1);
      } catch (error) {
        console.error(error);
        alert(error);
      }
    };

    onMounted(async () => {
      await fetchMemberInfo(memberIdParam.value);
      window.addEventListener('message', mobileAuthCallbackHandler);
    });

    onUnmounted(() => {
      window.removeEventListener('message', mobileAuthCallbackHandler);
    });

    const fetchMemberInfo = async (memberId) => {
      try {
        const {data} = await partnerAPI.partnerMember.partnerMember({
          memberId,
        });

        console.log('data', data);
        memberDetail.value = (data as any).data;
        if (!memberDetail.value.member.defaultShippingAddress) {
          memberDetail.value.member.defaultShippingAddress = {};
        }

      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const handleCheckAccount = async () => {
      try {
        const {data} = await partnerAPI.partnerAuth.companyCheckUsingPOST({
          param: {
            bankCode: memberDetail.value.partner.bankCode,
            bankAccount: memberDetail.value.partner.bankAccount,
          },
        });
        if ((data as any).success) {
          memberDetail.value.partner.accountHolder = (data as any).data;
        }
        alert((data as any).message);
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    };


    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    //TO DO 프로필이미지
    return {
      bankOptions,
      memberIdParam,
      memberDetail,
      handleChangeFile,
      onlyNumberAccount,
      handleOpenSearchAddress,
      openMobileAuth,
      resetPassword,
      gotoDetail,
      handleSubmit,
      changeAddress,
      fetchMemberInfo,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
      handleCheckAccount,
    };
  },
});
