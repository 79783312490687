<template>
  <Container v-if="memberDetail" :style="{ padding: 0 }">
    <div
      class="h-15 px-6 bg-black flex justify-between text-white items-center"
    >
      <div class="text-xl flex items-center font-bold">MEMBER# {{ memberDetail.member.memberId }}
      </div>
      <div v-if="memberDetail.memberType === 'MEMBER'" class="text-sm">Created :
        {{ formatDate(memberDetail.member.createdAt) || '-' }} | Updated :
        {{ formatDate(memberDetail.member.updatedAt) || '-' }}
      </div>
      <div v-else class="text-sm">Created : {{ formatDate(memberDetail.partner.createdAt) || '-' }} | Updated :
        {{ formatDate(memberDetail.partner.updatedAt) || '-' }}
      </div>
    </div>
    <div class="pt-14 pb-10 px-10 mb-10 relative">
      <div>
        <div class="font-bold text-xl">기본 정보</div>
        <div class="mt-2 text-sm">
          <template v-if="memberDetail.memberType === 'PARTNER'">
            <div class="grid grid-cols-2 ProductInfoTable__row">
              <div class="flex">
                <div class="InfoTable__labelColumn">회원명</div>
                <div>
                  {{ memberDetail.partner.companyName }}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">회원구분</div>
                <div>{{
                    memberDetail.memberType === 'MEMBER' ? '일반회원' : memberDetail.partner.approved ? '파트너회원' : '파트너(승인대기)'
                  }}
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2 ProductInfoTable__row">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">회원아이디(이메일)</div>
                <div>{{
                    memberDetail.memberType === 'MEMBER' ? memberDetail.member.email : memberDetail.partner.csEmail
                  }}
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">비밀번호</div>
                <div>
                  <button class="px-3 inline-flex items-center rounded-sm py-1 bg-black text-sm text-white">
                    <IconBase class="mr-1">
                      <MailIcon></MailIcon>
                    </IconBase>
                    비밀번호 재설정
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="grid grid-cols-2 ProductInfoTable__row">
              <div class="flex">
                <div class="InfoTable__labelColumn">회원명</div>
                <div>
                  {{
                    memberDetail.memberType === 'MEMBER' ? memberDetail.member.name : memberDetail.partner.companyName
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">회원구분</div>
                <div>{{ memberDetail.memberType === 'MEMBER' ? '일반회원' : '파트너회원' }}</div>
              </div>
            </div>
            <div class="grid grid-cols-2 ProductInfoTable__row">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">회원아이디(이메일)</div>
                <div>{{
                    memberDetail.memberType === 'MEMBER' ? memberDetail.member.email : memberDetail.partner.csEmail
                  }}
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">비밀번호</div>
                <div>
                  <button class="px-3 inline-flex items-center rounded-sm py-1 bg-black text-sm text-white"
                          @click="resetPassword(memberDetail.member.memberId)">
                    <IconBase class="mr-1">
                      <MailIcon></MailIcon>
                    </IconBase>
                    비밀번호 재설정
                  </button>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2 ProductInfoTable__row">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">휴대폰번호</div>
                <div class="flex items-center">
                  <input id="mobile" v-model="memberDetail.member.mobile" disabled type="text"
                         class="h-9 w-80 border border-gray-300 bg-gray-100 px-2 text-gray-400 mr-2 admin-input">
                  <button class="px-3 inline-flex items-center rounded-sm py-1 bg-black text-sm text-white"
                          @click="openMobileAuth">
                    인증하기
                  </button>
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">생년월일</div>
                <div>{{ memberDetail.member.birthday || '-' }}</div>
              </div>
            </div>
            <div class="ProductInfoTable__row with-border">
              <div class="flex">
                <div class="InfoTable__labelColumn">기본주소</div>
                <div class="flex items-center">
                  <input type="text" v-model="memberDetail.member.defaultShippingAddress.postcode" disabled
                         class="admin-input h-9 w-20 border border-gray-300 bg-gray-100 px-2 text-gray-400 mr-2">
                  <button
                    class="w-24 h-9 border border-blue-sky text-xs mr-2"
                    @click="handleOpenSearchAddress"
                  >
                    주소검색
                  </button>
                  <input type="text" v-model="memberDetail.member.defaultShippingAddress.address1" disabled
                         class="h-9 w-80 border border-gray-300 bg-gray-100 px-2 text-gray-400 mr-2 admin-input">
                  <input type="text" v-model="memberDetail.member.defaultShippingAddress.address2"
                         class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input"
                         @keyup="changeAddress">
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="memberDetail.memberType === 'PARTNER'" class="mt-10">
        <div class="font-bold text-xl">사업자정보</div>
        <div class="mt-2 text-sm">
          <div class="ProductInfoTable__row with-button">
            <div class="grid grid-cols-3">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">사업자등록번호</div>
                <div>{{ memberDetail.partner.businessNumber || '-' }}</div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">상호</div>
                <div>{{ memberDetail.partner.companyName || '-' }}</div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">사업자등록증</div>
                <div>
                  <a v-if="memberDetail.partner.businessLicense" :href="memberDetail.partner.businessLicense"
                     class="download-link member-link inline-flex" target="_blank">
                    <IconBase>
                      <FileImage></FileImage>
                    </IconBase>
                    <span class="ml-1">사업자등록증</span>
                  </a>
                  <HiddenFileInput
                    className="px-3 inline-flex items-center rounded-sm py-1 bg-black text-sm text-white ml-4"
                    :onChange="(e) => handleChangeFile('businessLicense')(e)"
                  >
                    <IconBase class="mr-1">
                      <UploadIcon></UploadIcon>
                    </IconBase>
                    파일 업로드
                  </HiddenFileInput>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-button">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">업태</div>
              <div>
                <input type="text" v-model="memberDetail.partner.industry"
                       class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
              </div>
            </div>
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">종목</div>
              <div>
                <input type="text" v-model="memberDetail.partner.sector"
                       class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-button">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">대표자 이름</div>
              <div>{{ memberDetail.partner.ceoName || '-' }}</div>
            </div>
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">대표자 휴대폰번호</div>
              <div class="flex items-center">
                <input id="partnerMobile" v-model="memberDetail.partner.ceoMobile" disabled type="text"
                       class="h-9 w-80 border border-gray-300 bg-gray-100 px-2 text-gray-400 mr-2">
                <button class="px-3 inline-flex items-center rounded-sm py-1 bg-black text-sm text-white"
                        @click="openMobileAuth">
                  인증하기
                </button>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-button">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">대표 이메일</div>
              <div>
                <input type="text" v-model="memberDetail.partner.csEmail"
                       class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
              </div>
            </div>
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">통신판매업신고번호</div>
              <div>
                <input type="text" v-model="memberDetail.partner.onlineSalesBusinessNo"
                       class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
              </div>
            </div>
          </div>
          <div class="ProductInfoTable__row with-button">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">주소</div>
              <div>
                <input type="text" v-model="memberDetail.partner.postNo" disabled
                       class="h-9 w-20 border border-gray-300 bg-gray-100 px-2 text-gray-400 mr-2">
                <button
                  class="w-22 h-9 border border-blue-sky text-xs mr-2"
                  @click="handleOpenSearchAddress"
                >
                  주소검색
                </button>
                <input type="text" v-model="memberDetail.partner.address1" disabled
                       class="h-9 w-80 border border-gray-300 bg-gray-100 px-2 text-gray-400 mr-2">
                <input type="text" v-model="memberDetail.partner.address2"
                       class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
              </div>
            </div>
          </div>
          <div class="ProductInfoTable__row with-button">
            <div class="grid grid-cols-3">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">운영담당자</div>
                <div>
                  <input type="text" v-model="memberDetail.partner.managerName"
                         class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">운영담당자 휴대폰번호</div>
                <div>
                  <input type="text" v-model="memberDetail.partner.managerMobile"
                         class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">운영담당자 이메일</div>
                <div>
                  <input type="text" v-model="memberDetail.partner.managerEmail"
                         class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
                </div>
              </div>
            </div>
          </div>
          <div class="ProductInfoTable__row">
            <div class="grid grid-cols-3">
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">정산담당자</div>
                <div>
                  <input type="text" v-model="memberDetail.partner.paymentManagerName"
                         class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">정산담당자 휴대폰번호</div>
                <div>
                  <input type="text" v-model="memberDetail.partner.paymentManagerMobile"
                         class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
                </div>
              </div>
              <div class="flex items-center">
                <div class="InfoTable__labelColumn">정산담당자 이메일</div>
                <div>
                  <input type="text" v-model="memberDetail.partner.paymentManagerEmail"
                         class="h-9 w-80 border border-gray-300 px-2 text-black mr-2 admin-input">
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border gap-96">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">입금계좌</div>
              <div class="flex items-center">
                <Select
                  :options="bankOptions"
                  :value="memberDetail.partner.bankCode"
                  :onChange="
                    (e) => {
                      memberDetail.partner.bankCode = e.target.value;
                      memberDetail.partner.bankName = bankOptions.find(
                        (option) => option.value === e.target.value
                      ).label;
                    }
                  "
                  placeholder="은행선택"
                  className="h-9 w-42 border border-gray-300 px-2 text-black mr-2 admin-input"
                ></Select>
                <div class="hyphen mr-2">-</div>
                <input
                  type="text"
                  placeholder="계좌번호는 숫자만 입력해주세요."
                  class="h-9 w-64 border border-gray-300 px-2 text-black mr-2 admin-input "
                  v-model="memberDetail.partner.bankAccount"
                  @Keyup="onlyNumberAccount(memberDetail.partner.bankAccount)"
                />
                <button
                  class="w-24 h-9 border border-blue-sky text-xs mr-2"
                  @click="handleCheckAccount"
                >
                  예금주명 조회
                </button>
                <input type="text" v-model="memberDetail.partner.accountHolder" disabled
                       class="h-9 w-64 border border-gray-300 bg-gray-100 px-2 text-gray-400">
              </div>
            </div>
            <div class="flex items-center ml-14">
              <div class="InfoTable__labelColumn">통장사본</div>
              <div>
                <a :href="memberDetail.partner.bankbookCopy" class="download-link member-link inline-flex"
                   target="_blank">
                  <IconBase>
                    <FileImage></FileImage>
                  </IconBase>
                  <span class="ml-1">통장사본</span>
                </a>
                <HiddenFileInput
                  className="px-3 inline-flex items-center rounded-sm py-1 bg-black text-sm text-white ml-4"
                  :onChange="(e) => handleChangeFile('bankbookCopy')(e)"
                >
                  <IconBase class="mr-1">
                    <UploadIcon></UploadIcon>
                  </IconBase>
                  파일 업로드
                </HiddenFileInput>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="cols mr-10">
            <div class="flex items-center mt-10">
              <h2 class="text-sm ">셀러홈 배너 이미지</h2>
              <p class="ml-4 text-xs text-gray-500">(1600 * 400 사이즈)</p>
            </div>
            <div class="mt-2">
              <label
                class="
                  border
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                  drag-container
                  relative
                "
                style="width: 800px; height: 200px;"
                :for="`sellerImg1`"
              >
                <input
                  type="file"
                  name="productImageList"
                  accept=".pdf,.jpg,.jpeg,.png"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  id="sellerImg1"
                  ref="sellerImg1"
                  @change="(e) => handleChangeFile('homeBannerImage')(e)"
                />
                <span
                  class="w-full	h-full bg-cover"
                  :style="{
                    backgroundImage: `url(${memberDetail.partner.homeBannerImage ? memberDetail.partner.homeBannerImage : '/images/seller-bg.jpeg'})`,
                  }"
                ></span>
                <span class="hidden justify-center items-center hover-profile-text">
                  <IconBase
                    :width="48"
                    :height="48"
                    color="#ffffff"
                    viewBox="0 0 48 48"
                  >
                    <PictureIcon></PictureIcon>
                  </IconBase>
                </span>
              </label>
            </div>
          </div>
          <div>
            <div class="flex items-center mt-10">
              <h2 class="text-sm ">프로필 사진</h2>
              <p class="ml-4 text-xs text-gray-500">(100 * 100 사이즈) 이상</p>
            </div>
            <div class="mt-2">
              <label
                class="
                  border
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                  drag-container
                  relative
                  rounded-full
                "
                style="width: 200px; height: 200px;"
                :for="`sellerImg2`"
              >
                <input
                  type="file"
                  name="productImageList"
                  accept=".pdf,.jpg,.jpeg,.png"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  id="sellerImg2"
                  ref="sellerImg2"
                  @change="(e) => handleChangeFile('profileImage')(e)"
                />
                <span
                  class="w-full	h-full bg-cover rounded-full"
                  :style="{
                    backgroundImage: `url(${memberDetail.partner.profileImage ? memberDetail.partner.profileImage : '/images/default-img-blue.png'})`,
                  }"></span>
                <span class="hidden rounded-full justify-center items-center hover-profile-text">
                  <IconBase
                    :width="48"
                    :height="48"
                    color="#ffffff"
                    viewBox="0 0 48 48"
                  >
                    <PictureIcon></PictureIcon>
                  </IconBase>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <button
          class="
            w-60
            h-16
            border border-black
            shadow
            flex
            items-center
            justify-center
            mt-10
            mr-10
            admin-button
          "
          @click="gotoDetail"
        >
          <IconBase>
            <UndoIcon></UndoIcon>
          </IconBase>
          <span class="ml-2">취소</span>
        </button>
        <button
          class="
            w-60
            h-16
            border border-black
            shadow
            flex
            items-center
            justify-center
            mt-10
            admin-button
          "
          type="submit"
          @click="handleSubmit(memberDetail.member.memberId)"
        >
          <IconBase>
            <CheckCircleIcon></CheckCircleIcon>
          </IconBase>
          <span class="ml-2">확인</span>
        </button>
      </div>
      <router-link to="/app/member/list" class="mt-4 member-list__link flex items-center">
        <IconBase :width="24" :height="24" class="mr-1">
          <ArrowLeftIcon></ArrowLeftIcon>
        </IconBase>
        목록으로
      </router-link>
      <div id="form" style="display: none"></div>
    </div>
  </Container>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, onUnmounted} from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import HiddenFileInput from '@/components/HiddenFileInput.vue';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import UploadIcon from '@/components/icons/UploadIcon.vue';
import UndoIcon from '@/components/icons/UndoIcon.vue';
import FileImage from '@/components/icons/FileImage.vue';
import MailIcon from '@/views/layouts/AppLayout/icons/MailIcon.vue';
import uploadImageFiles from '@/utils/uploadSignupImageFiles';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import bankCode from '@/constants/bankCode';
import Select from '@/components/Select.vue';
import PictureIcon from '@/views/layouts/AppLayout/icons/PictureIcon.vue';

export default defineComponent({
  name: 'MemberEdit',
  components: {
    Select,
    Container,
    IconBase,
    CheckCircleIcon,
    FileImage,
    UndoIcon,
    MailIcon,
    ArrowLeftIcon,
    HiddenFileInput,
    UploadIcon,
    PictureIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const memberDetail = ref<any>(null);
    const memberIdParam = ref<number>();
    const bankOptions = ref(bankCode);
    const imgFiles = ref({
      bankbookCopy: '',
      businessLicense: '',
    });
    const onlyNumberAccount = (input) => {
      memberDetail.value.partner.bankAccount = input.replace(/[^0-9]/g, '');
    };

    const changeAddress = () => {
      memberDetail.value.member.defaultShippingAddress.requireUpdate = true;
    };
    const openMobileAuth = async () => {
      const {data} = await partnerAPI.memberAuth.identificationInfoUsingGET();
      const encodeData = (data as any).data;

      const form = document.createElement('form');
      form.setAttribute('name', 'formChk');
      form.setAttribute(
        'action',
        'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'
      );
      form.setAttribute('target', 'popupChk');

      const field = document.createElement('input');
      field.setAttribute('name', 'm');
      field.setAttribute('value', 'checkplusService');
      form.appendChild(field);

      const field2 = document.createElement('input');
      field2.setAttribute('name', 'EncodeData');
      field2.setAttribute('value', encodeData);
      form.appendChild(field2);

      const htmlForm = document.getElementById('form');
      const chkForm = document.getElementById('formChk');
      if (chkForm) {
        if (chkForm.parentNode != null) {
          chkForm.parentNode.removeChild(chkForm);
        }
      }
      if (htmlForm != null) {
        htmlForm.append(form);
      }

      window.open(
        '',
        'popupChk',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      );
      form.submit();
    };

    const handleChangeFile = (fieldName: string) => async (e) => {
      const [url] = await uploadImageFiles({
        files: e.target.files as FileList,
      });
      imgFiles.value[fieldName] = e.target.files && e.target.files[0].name;
      console.log('url', url, e.target, fieldName);
      if (url) {
        memberDetail.value.partner[fieldName] = url;
      } else {
        alert('이미지 업로드에 실패했습니다.');
      }
    };

    const gotoDetail = () => {
      router.go(-1);
    };

    const handleOpenSearchAddress = () => {
      new (window.daum as any).Postcode({
        oncomplete: function (data) {
          if (memberDetail.value.memberType === 'MEMBER') {
            memberDetail.value.member.defaultShippingAddress.postcode = data.zonecode;
            memberDetail.value.member.defaultShippingAddress.address1 = data.address;
            memberDetail.value.member.defaultShippingAddress.requireUpdate = true;
          } else {
            memberDetail.value.partner.postNo = data.zonecode;
            memberDetail.value.partner.address1 = data.address;
          }


          // console.group('postcode');
          // console.log('address ', data.address);
          // console.log('zonecode ', data.zonecode);
          // console.groupEnd();
        },
      }).open();
    };

    const resetPassword = async (memberId) => {
      try {
        const {data} = await partnerAPI.partnerMember.partnerMemberResetPassword({
          memberId,
        });
        alert(data.message);
      } catch (error) {
        console.error(error);
        alert(error);
      }
    };

    const mobileAuthCallbackHandler = ({data, origin}) => {
      if (origin.includes('api.blacklot.com')) {
        console.log('휴대퐁 인증 콜백 들어옴');
        console.log(data);
        const {name, birth, dupInfo, gender, mobile} = JSON.parse(data);
        if (memberDetail.value.memberType === 'MEMBER') {
          if (dupInfo === memberDetail.value.member.dupInfo) {
            memberDetail.value.member.mobile = mobile;
          } else {
            alert('본인 휴대폰이 아닙니다.');
          }
        } else {
          memberDetail.value.partner.ceoMobile = mobile;
        }
      }
    };

    const handleSubmit = async (memberId) => {
      const body = memberDetail.value;
      console.log('body', body)
      try {
        const {data} = await partnerAPI.partnerMember.partnerMemberUpdate({
          body
        });
        alert(data.message);
        router.go(-1);
      } catch (error) {
        console.error(error);
        alert(error);
      }
    };

    onMounted(async () => {
      await fetchMemberInfo(memberIdParam.value);
      window.addEventListener('message', mobileAuthCallbackHandler);
    });

    onUnmounted(() => {
      window.removeEventListener('message', mobileAuthCallbackHandler);
    });

    const fetchMemberInfo = async (memberId) => {
      try {
        const {data} = await partnerAPI.partnerMember.partnerMember({
          memberId,
        });

        console.log('data', data);
        memberDetail.value = (data as any).data;
        if (!memberDetail.value.member.defaultShippingAddress) {
          memberDetail.value.member.defaultShippingAddress = {};
        }

      } catch (e) {
        alert(getServerErrorMessage(e));
      }
    };

    const handleCheckAccount = async () => {
      try {
        const {data} = await partnerAPI.partnerAuth.companyCheckUsingPOST({
          param: {
            bankCode: memberDetail.value.partner.bankCode,
            bankAccount: memberDetail.value.partner.bankAccount,
          },
        });
        if ((data as any).success) {
          memberDetail.value.partner.accountHolder = (data as any).data;
        }
        alert((data as any).message);
      } catch (e) {
        console.error(e);
        alert(getServerErrorMessage(e));
      }
    };


    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    //TO DO 프로필이미지
    return {
      bankOptions,
      memberIdParam,
      memberDetail,
      handleChangeFile,
      onlyNumberAccount,
      handleOpenSearchAddress,
      openMobileAuth,
      resetPassword,
      gotoDetail,
      handleSubmit,
      changeAddress,
      fetchMemberInfo,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
      handleCheckAccount,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProductInfoTable__row {
  border-bottom: 1px solid #ddd;
  padding: 0.65rem 0;

  &:last-child {
    border-bottom: none;
  }

  &.with-border {
    border-bottom: 1px solid #ddd;
  }

  &.with-button {
    padding: 5px 0;
  }
}

.InfoTable__labelColumn {
  color: #999;
  width: 144px;
  flex-shrink: 0;
}

.member-list__link {
  position: absolute;
  bottom: -60px;
  left: 0;
}

@import "../../../assets/style/label";

.hover-profile-text {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.drag-container:hover {
  .hover-profile-text {
    display: flex;
  }
}
</style>
